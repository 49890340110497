import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/Layout';
import Hero from 'components/Hero';
import SplitSection from '../../components/SplitSection';
import USPs from 'components/USPs';
import FAQs from '../../components/FAQs';
import RegisterInterest from '../../components/RegisterInterest';

import img_bar from '../../assets/images/bar.jpg';
import img_youpos_admin from '../../assets/images/youpos-admin-mockup.png';
import img_youpos_shop from '../../assets/images/youpos-shop-mockup.png';
import img_qr_code from '../../assets/images/youpos-qr-code.png';

const HowItWorks = () => {
	return (
		<Layout pageName="how-it-works">
			<Helmet>
                <title>QR Based Table Ordering System</title>
				<link rel="canonical" href="https://www.youpos.co.uk/how-it-works" />
            </Helmet>
			<Hero 
                image={img_bar}
                title="How It Works"
				subtitle=""
				imageClass="u-cover--bottom"
            />
			<main id="how-it-works">
				<header className="u-text-center o-section u-pb-0">
					<div className="o-wrapper o-wrapper--narrow">
						<h2>YouPos makes table ordering a breeze.</h2>
						<p>Get started today - no hardware to buy or apps to download, just a simple online store for your&nbsp;business.</p>
					</div>
				</header>
				<SplitSection
					image={img_youpos_admin}
					containImage={true}
					sectionClass="u-pb-0"
					content={<>
						<h3>Start Your Online Store</h3>
						<p>Our sign up process is quick and easy. Create your account in minutes with just a few details, add multiple users and customise your store - add your logo and brand colours to personalise your customer's experience!</p>
						<p>Once your account is set up you can add your menus and products, and connect your payment provider - YouPos works with Stripe, WorldPay, Elavon and more!</p>
					</>}
				/>
				<SplitSection
					image={img_qr_code}
					containImage={true}
					sectionClass="u-pb-0"
					reverse={true}
					content={<>
						<h3>Get Your QR Codes</h3>
						<p>Once you've set up your store, you can add in your tables - a unique QR code will be generated for each one. These can then be printed and added to your tables.</p>
						<p>Alternatively, you can order our customised table number sets and we'll do the work for you!</p>
					</>}
				/>
				<SplitSection
					image={img_youpos_shop}
					containImage={true}
					content={<>
						<h3>Take Orders</h3>
						<p>Customers can scan the QR code using their smartphone or tablet and will be instantly taken to your menu, with their table number stored. They can choose their products, customise their order and pay via credit card, Apple Pay or Google Pay.</p>
						<p>Orders will popup on your online account - once you've accpeted the order, the customer's card is charged and you can prepare and deliver the order right to their table.</p>
					</>}
				/>
				<USPs />
				<FAQs />
				<RegisterInterest />
			</main>
		</Layout>
	);
};

export default HowItWorks;