import React from 'react';

const SplitSection = ({
	image,
	content,
	reverse,
	sectionClass,
	containImage,
}) => {

	const contentClass = reverse ? 'u-order-12' : 'u-order--1 u-order-0@tm';

	const imageClass = containImage ? 'u-contain' : 'u-cover';

	return (
		<section className={`c-split-section o-section ${sectionClass}`}>
			<div className="o-wrapper">
				<div className="o-grid o-grid--space-between">
					<div className={`o-grid__col o-grid__col-6 o-grid__col-12@tm u-flex ${contentClass}`}>
						<div className="c-split-section__col c-split-section__col--content">
							{content || null}
						</div>
					</div>
					<div className="o-grid__col o-grid__col-5 o-grid__col-5@ds o-grid__col-12@tm u-order--1@tm">
						<div className="c-split-section__col c-split-section__col--media o-media">
							<img src={image} alt="" className={imageClass} />
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default SplitSection;